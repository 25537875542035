@import "../../../variables";
@import "../../../mixins";

.button {
    @include basicButton;

    background-color: var(--color-accent);
    display: flex;
    justify-content: center;

    &:hover {
        background-color: var(--color-accent-darker);
    }

    &.disabled {
        cursor: default;
        background-color: var(--color-background-medium-dark);
        color: var(--color-accent-darker);
    }

}
