@import "../../../mixins";
@import '../../../variables';

.floatingActionButton {
    position: absolute;

    $padding: 32px;
    bottom: $padding;
    right: $padding;

    $size: 48px;
    height: $size;
    width: $size;

    border-radius: 50%;

    background: var(--color-primary-button);
    cursor: pointer;

    > * {
        @include centered;
    }

    &:hover {
        background: var(--color-primary-button-hover);
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        position: fixed;
    }
}
