@import "../../../variables";
@import "../../../mixins";

.field {
    @include fieldWithLabel;

    &.error {
        border-color: var(--color-error);
    }
}
