.hiddenLogoffButton {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 40px;
    height: 40px;

    background: var(--color-background-dark);

    border-radius: var(--radius-default);
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: none;
    }

    &:hover {
        img {
            display: block;
        }

        background: var(--color-background-darker);
    }
}
