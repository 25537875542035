@import "../../../variables";

.background {
    $padding: 24px;

    background-color: rgba(20, 20, 20, 0.8);
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, .8);
    }

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    .dialog {
        background-color: var(--color-background-dialog);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: $padding;
        border-radius: var(--radius-default);

        width: fit-content;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .title{
                display: flex;
                align-items: center;

                .leading{
                    margin-right: 8px;
                }
            }

            .close {
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }

                @media (prefers-color-scheme: dark) {
                    filter: invert(0.7);
                }
            }
        }

        .subtitle {
            font-weight: 200;
        }

        .actions {
            display: flex;
            justify-content: flex-end;

            > * {
                margin-left: 16px;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        .dialog {
            $paddingBottom: 32px;
            max-height: calc(100vh - #{$padding} - #{$paddingBottom});
            width: calc(100% - #{$sizeMarginCard * 2});

            position: fixed;
            top: unset;
            bottom: 0;
            left: unset;

            transform: unset;

            padding-bottom: 32px;

            border-radius: var(--radius-card) var(--radius-card) 0 0;
        }
    }
}
