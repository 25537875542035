.orderDetails {
    .info {
        margin: 32px 0;

        display: flex;
        flex-wrap: wrap;

        > * {
            margin-right: 24px;
            margin-bottom: 16px;
        }
    }

    .tableProducts table {
        width: 100%;

        tr td {
            cursor: pointer;
        }
    }
}
