@mixin basicButton {
    padding: 12px;
    border-radius: var(--radius-default);

    text-align: center;

    font-size: 1.3em;
    font-weight: bold;

    cursor: pointer;

    transition: background-color .3s;
}

@mixin animation($properties...) {
    transition-property: $properties;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

@mixin ellipses($size) {
    &::after {
        position: absolute;
        content: '...';
        color: white;
        left: $size;
        @include animation(color);
    }
}

@mixin change-color-transition {
    transition-property: color, border-color, filter;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}


@mixin full-screen($top: 0) {
    position: absolute;
    left: 0;
    top: $top;
    bottom: 0;
    right: 0;
}


@mixin backgroundBlur {
    content: ' ';
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    background-color: rgba(20, 20, 20, 0.8);
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        backdrop-filter: blur(4px);
        background-color: rgba(200, 200, 200, .8);
    }
}

@mixin basicOrderCard {
    margin: 8px 16px;
    position: relative;

    display: flex;
    flex-direction: column;
    padding: 24px;
    min-width: 200px;

    background: var(--color-coupon);
}

@mixin baseCard {
    margin: 24px;
    border-radius: var(--radius-default);
    background: var(--color-background-light);
}

@mixin centered {
    position: relative;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

@mixin labelTitle {
    display: block;
    font-size: 8px;
    font-weight: 500;

    opacity: .7;
}

@mixin fieldWithLabel {
    margin: 16px 0;

    position: relative;
    border: 2px var(--color-border-spacing) solid;
    border-radius: var(--radius-default);

    display: flex;
    background: var(--color-background-dark);

    .input {
        border: none;
        width: 100%;

        background: var(--color-background-dark);

        margin: 0;
        padding: 4px 16px;

        font-size: 0.9rem;
        color: var(--color-text-small-subtitle);
        border-radius: var(--radius-default);

        height: 3rem;

        &:focus {
            border-bottom: none !important;
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
            outline: none !important;
        }

        &::placeholder {
            color: transparent;
            @include animation(color);
        }
    }

    &:focus-within, &.has-value {
        label {
            top: 0;
            left: 16px;
            transform: translateY(-55%);
            background: var(--color-background-dark);

            font-size: .9em;
        }

        .input::placeholder {
            color: var(--color-text-level);
        }
    }

    &:focus-within {
        border: 2px var(--color-accent-darker) solid;

        label {
            color: var(--color-accent-lighter);
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 8px;

        padding: 0 8px;

        transform: translateY(-50%);

        line-height: 1rem;
        font-size: 1.1rem;

        cursor: text;

        color: var(--color-text-small-subtitle);
        @include animation(font-size, top, left, border);
    }
}

@mixin selectAnOptionDialog {

    display: none;
    width: 100%;
    background: var(--color-background-dialog);
    z-index: 1;
    position: absolute;
    left: 0;
    top: 8px;

    border-radius: var(--radius-default);

    span {
        cursor: pointer;

        padding: 8px;

        &:first-child {
            padding: 16px 8px 8px 8px;
        }

        &:last-child {
            padding: 8px 8px 16px 8px;
        }

        display: block;

        &:hover {
            background: var(--color-background-level);
        }

        &.active {
            background: var(--color-border-spacing);
            color: var(--color-selected);
            font-weight: 500;
        }
    }
}
