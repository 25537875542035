.changePassword {
    min-width: 400px;

    .field {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;

        & >:not(:last-child){
            margin-right: 8px;
        }
    }
}

.error {
    font-size: 10px;
    color: var(--color-error);
}
