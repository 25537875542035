.baseFormProduct {
    width: 360px;

    h2 {
        margin-top: 24px;
    }

    h3 {
        margin-top: 16px;
    }

    .field {
        padding-bottom: 16px;
    }

    .isEnabledToggle {
        margin: 24px 0;
    }

    .actions {
        margin-top: 32px;
        display: flex;
    }

    .images {
        display: flex;
        margin: 24px 0;
    }
}
