@import '../../../mixins';
@import "../../../variables";


.dropdowns {
    position: relative;

    .popover {

        display: none;

        z-index: 1;
        position: absolute;
        top: 100%;
        right: 0;

        padding: 8px 0;

        background: var(--color-background-dialog);
        color: var(--color-font-on-primary);

        $radius: var(--radius-default);
        border-radius: $radius;
        cursor: pointer;

        .dropDownItem {
            text-align: left;
            display: flex;
            padding: 8px 16px;

            img {
                width: 24px;
            }

            span {
                white-space: nowrap;
                margin-left: 8px;
                width: 128px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }

            &:hover {
                background-color: var(--color-primary-hover);
            }

            &:first-child {
                border-top-left-radius: $radius;
                border-top-right-radius: $radius;
            }

            &:last-child {
                border-bottom-left-radius: $radius;
                border-bottom-right-radius: $radius;
            }
        }
    }

    &.opened .popover {
        display: block;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        position: unset;

        &.opened:after {
            @include backgroundBlur;
        }

        .popover {
            padding-top: 8px;
            padding-bottom: 8px;
            border-top-right-radius: var(--radius-card);
            border-top-left-radius: var(--radius-card);

            position: fixed;
            width: 100%;
            bottom: 0;
            top: unset;
            z-index: 2;

            .dropDownItem {
                padding: 16px 24px;

                &:last-child {
                    border-bottom-left-radius: none;
                    border-bottom-right-radius: none;
                }
            }
        }

    }
}
