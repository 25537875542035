@import '../../../variables';


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-family: "Biotif", sans-serif;
        margin-bottom: 16px;
    }

    .actions {
        display: flex;

        > * {
            margin: 0 8px;
        }
    }


    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .actions > * {
            margin-right: 0;
        }
    }
}
