@import "../../../variables";

.appBar {
    background: var(--color-app-bar);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;

    .logo {
        cursor: pointer;

        img {
            width: 80px;
        }
    }

}

