.avatar{
    border-radius: 50%;
    text-align: center;
    background: var(--color-primary);
    cursor: pointer;

    div{
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        color: var(--color-font-on-primary);
        font-weight: 700;
    }

    &.normal{
        $size: 40px;

        height: $size;
        width: $size;
        letter-spacing: 1px;
    }

    &.small{
        $size: 20px;

        height: $size;
        width: $size;

    }

    &.big{
        $size: 80px;

        height: $size;
        width: $size;
        font-size: 32px;
    }
}
