@import "../../variables";

.areas{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding: 16px 0 24px 0;

    .list{
        flex-grow: 1;
        padding-left: 16px;
        overflow-y: auto;
        max-height: 320px;
    }

    .areaItem{
        align-items: center;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;

        > :first-child {
            width: 58%;
        }

        > :nth-child(2) {
            width: 30%;
        };

        &:last-child {
            padding-bottom: 30px
        }

        &.editedAreaItem{
            padding: 0 16px 0 8px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        padding: 0;
        .list{
            padding: 16px 0;
        }

        .areaItem{
            padding: 0;

            &.editedAreaItem{
                padding: 0;
            }
        }
    }
}