.removableImage {
    margin-right: 16px;
    position: relative;

    .close {
        position: absolute;
        right: 2px;
        top: 2px;
        cursor: pointer;
        z-index: 4;

        filter: invert(1);

        &:hover{
            filter: invert(.7);
        }
    }

    .wrapperImage {
        img {
            max-width: 240px;
            max-height: 180px;
        }
    }
}