.products {
    .tableProducts {
        table {
            width: 100%;

            .sortIndex {
                display: flex;
                align-items: center;
            }

            td, th {
                &:first-child {
                    width: 3%;
                }

                &:last-child {
                    width: 3%;
                }
            }

            tr td {
                cursor: pointer;

                &:nth-child(2) {
                    text-transform: capitalize;
                }

                .isEnabled {
                    display: flex;
                    justify-content: center;
                    text-align: center;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
