.openedHoursHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
}

.openedHours {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto auto auto 10%;

    align-items: center;
}
