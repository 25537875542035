.coupons {
    table {
        width: 100%;

        td, th {
            &:last-child {
                width: 3%;
            }
        }

        tr td {
            cursor: pointer;
        }
    }
}
