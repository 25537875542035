@import '../../../mixins';

.operatorOrderCard {
    @include basicOrderCard;

    border-radius: var(--radius-default);

    justify-content: space-between;

    .details {
        > * {
            margin-bottom: 8px;
        }

        .title {
            text-align: center;
            margin-bottom: 16px;
        }


        .products {
            display: flex;
            flex-direction: column;
        }

        .orderProduct {
            .observation {
                display: block;
                color: var(--color-accent-lighter);
            }
        }

    }

    .actions {
        margin-top: 40px;
    }
}
