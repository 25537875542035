.label {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .title {
        display: block;
        font-size: 14px;
        font-weight: 500;

        opacity: .7;
    }

    .content {
        font-weight: 400;
        font-size: 18px;

        font-family: "Biotif", sans-serif;

        display: flex;
        max-width: 50vw;
        overflow-wrap: anywhere;
    }
}
