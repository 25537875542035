@import "../../../mixins";

.outline-button {
    @include basicButton;

    border: 1px solid var(--color-text-headers-inverse);

    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--color-background-medium-dark);
    }
}
