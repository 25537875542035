@import "../../variables";

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(8px);
    }
    100% {
        transform: translateY(0px);
    }
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .logoBox {
        padding: 48px;
        border: 1px solid var(--color-accent);
        border-radius: var(--radius-default);

        .logo {
            width: 240px;
            margin-bottom: 48px;

            @media (prefers-reduced-motion: no-preference) {
                animation: App-logo-float infinite 3s ease-in-out;
            }
        }
    }

}

