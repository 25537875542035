@import "../../../mixins";

.selectField {
    position: relative;
    cursor: pointer;

    margin: 16px 0;
    padding: 16px;
    border: 2px var(--color-border-spacing) solid;
    border-radius: var(--radius-default);

    transition: background-color, border-color 0.4s;
    background: var(--color-background-dark);

    color: var(--color-text-small-subtitle);

    .label {
        position: absolute;

        font-size: 12px;
        font-weight: 600;
        margin-bottom: 4px;

        top: 0;
        left: 8px;
        transform: translateY(-55%);
        padding: 0 8px;
        background: var(--color-background-dark);
    }

    > span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:after {
            content: ' ';

            margin-left: 24px;

            $size: 8px;
            width: 0;
            height: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-top: $size solid var(--color-text-small-subtitle);
        }
    }

    .selectFieldOptions {
        @include selectAnOptionDialog;
    }

    &.open .selectFieldOptions {
        display: unset;

    }
}
