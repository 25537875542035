@import '../../mixins';

.chat {
    @include baseCard;
    background: var(--color-background-darker);

    display: flex;

    $heightChat: calc(100vh - 48px - 72px);
    height: $heightChat;

    .conversations {
        display: flex;
        flex-direction: column;
        width: 20%;
        border-right: 0.1px solid var(--color-background-medium-dark);

        > * {
            padding: 16px;
            border-bottom: 1px solid var(--color-background-medium-dark);
            cursor: pointer;

            &:hover {
                background: var(--color-background-transparent);
            }

            .name {
                font-size: 1.2rem;
                margin-bottom: 8px;
            }

            .lastMessage {
                font-size: 1.0rem;
                color: var(--color-background-concrete);

            }

            span {
                display: block;
            }

            &:last-child {
                border-bottom: none;
            }

            &.selected {
                background: var(--color-background-transparent);
            }

        }
    }

    .messages {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .content {
            flex-grow: 1;
            background: var(--color-background-transparent);
            overflow: scroll;

            padding: 16px;

            > div {
                padding: 16px;

                > span {
                    padding: 8px;
                    border-radius: 8px;
                }

                &.inbound {
                    text-align: left;

                    > span {
                        background: var(--color-accent-darker);
                    }
                }

                &.outbound {
                    text-align: right;

                    > span {
                        background: var(--color-accent-lighter);
                    }
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            padding: 14px;
            background: var(--color-app-bar);

            .field {
                margin: 0 8px;
                flex-grow: 1;
                padding: 0;

                .input {
                    padding: 0;
                }
            }

            .icon {
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                img {
                    width: 100%;
                    filter: invert(0.4);
                }
            }

        }
    }
}
