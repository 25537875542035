.categoryFilters {
    display: flex;
    flex-wrap: wrap;

    .option {
        margin: 8px;
        padding: 8px 16px;
        background: var(--color-background-darker);
        border-radius: var(--radius-default);

        cursor: pointer;

        &.active {
            background: var(--color-accent-darker);
        }

        &:hover {
            background: var(--color-accent);
        }

        &.all {
            position: relative;
            margin: 8px 24px 8px 8px;

            &:after {
                content: '';
                position: absolute;
                width: 2px;
                background: tomato;
                height: 100%;
                right: -16px;
                top: 0;
                background: var(--color-accent-lighter);
                border-radius: var(--radius-default);
            }

        }
    }
}
