@import "../../../variables";

.area {
    position: relative;

    .mapArea {
        width: 600px;
        max-width: 100%;
        height: 320px;

        z-index: 0;

        .closeButton{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 999;
            font-size: 22px;
            cursor: pointer;
            box-shadow: 0 1px 5px rgba(0,0,0,0.65);
            width: 30px;
            background-color: white;
            color: black;
            text-align: center;
            border-radius: 3px;
        }

        @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
            width: 80vw;
            height: 320px;
        }
    }
}

