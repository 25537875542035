@import '../../../mixins';

.kitchenOrderCard {
    @include basicOrderCard();

    text-align: center;

    .details {
        &:before {
            content: ' ';
            height: 12px;
            width: 100%;
            border-bottom: 2px dashed var(--color-border-spacing);
            position: absolute;
            top: 0;
            left: 0;
        }

        h4 {
            margin-bottom: 16px;
        }

        .orderProduct {
            .observation {
                display: block;
                color: var(--color-accent-lighter);
            }
        }
    }

    .orderId {
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: var(--color-accent-darker);
    }

    &:before {
        content: ' ';
        width: 104%;
        height: 6px;
        background-color: var(--color-accent-darker);
        position: absolute;
        top: -6px;
        left: -2%;
        border-radius: var(--radius-default);
    }
}
