@import './mixins';
@import './variables';

:root {
    --color-background-dark: #292929;
    --color-background-darker: #1F1F1F;
    --color-background-light: #313131;
    --color-background-level: #1E1E1E;
    --color-background-medium-dark: #383838;
    --color-background-transparent: #00000044;
    --color-background-concrete: #686868;
    --color-background-icon-button: #7A7A7A;
    --color-background-input-dark: #3F3F3F;
    --color-background-dialog: #121212;
    --color-coupon: #111111;

    --color-app-bar: #1f1f1f;
    --color-primary: rgb(41, 41, 41);
    --color-primary-hover: #ffffff19;

    --color-primary-button: #8d601e;
    --color-primary-button-hover: #ad7829;

    --color-text-headers-inverse: #FCFCFF;
    --color-text-paragraph-inverse: #9D9D9D;
    --color-text-input: #eeeeee;
    --color-text-small-subtitle: #B4B4B4;
    --color-border-light-default: #CACACA;
    --color-border-spacing: #ffffff19;
    --color-text-small-subtitle-inverse: #EBEDF5;
    --color-text-level: #9F98AE;
    --color-text-level-lighter: #b68f38;
    --color-accent-lighter: #b68f38;
    --color-accent: #8d601e;
    --color-accent-darker: #593e10;

    --color-selected: #b68f38;
    --color-switch-background: #593e10;
    --color-switch-off: rgba(194, 194, 194, 0.19);
    --color-switch-background-off: rgb(193 193 193);

    --color-helper-success: #5AAF7E;
    --color-helper-error: #F73D3C;
    --color-error: #F73D3C;
    --color-helper-warning: #FFE081;

    --vh: 1vh;
    --radius-default: 8px;
}


@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

html, body {
    margin: 0
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: var(--color-background-dark);
    color: var(--color-text-small-subtitle-inverse);

    font-family: syne, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: syne, sans-serif;
    font-weight: 700;
    color: var(--color-text-small-subtitle-inverse);
}

h3 {
    margin: 16px 0;
}

.accent-text {
    color: var(--color-accent);
}

span {
    font-size: 1.1em;
    font-family: syne, sans-serif;
}

a {
    color: var(--color-helper-success);
    cursor: pointer;
}


table {
    border-collapse: collapse;

    tbody tr:nth-child(odd) td {
        background: var(--color-background-dark);
    }

    thead th {
        padding: 16px 0;
    }

    td {
        font-size: 14px;
        padding: 8px 16px;

        font-weight: 500;
        text-align: center;
    }
}

$borderTable: var(--radius-default);

tr:first-child th:first-child {
    border-top-left-radius: $borderTable;
}

tr:first-child th:last-child {
    border-top-right-radius: $borderTable;
}

tr:last-child td:first-child {
    border-bottom-left-radius: $borderTable;
}

tr:last-child td:last-child {
    border-bottom-right-radius: $borderTable;
}


@media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
    table {
        display: inline;

        thead {
            display: none;
        }

        tbody {
            display: flex;
            flex-direction: column;

            tr {
                @include baseCard;

                display: flex;
                flex-direction: column;

                width: 100%;
                position: relative;
                margin: 16px 0;
                padding: 8px 0;

                &:nth-child(odd) td {
                    background: var(--color-background-light);
                }

                td {
                    display: inline;
                    text-align: left;

                    &:before {
                        content: attr(data-title);
                        @include labelTitle;
                    }
                }
            }
        }
    }
}
