.clientDetails {
    .tabContent {
        margin: 16px 0;

        table {
            width: 100%;

            &.tableOrders {
                tr td {
                    cursor: pointer;
                }
            }
        }
    }

    .info {
        margin-top: 16px;

        display: flex;
        flex-wrap: wrap;

        > * {
            margin: 0 24px 16px 0;
        }
    }
}
