@import '../../../variables';
@import '../../../mixins';

.tabs {
    width: 100%;
    display: flex;
    justify-content: left;

    > span {
        display: flex;
        align-items: center;
        justify-content: center;

        border-bottom: 1px solid var(--color-border-field);
        min-width: 180px;
        text-align: center;

        padding: 8px 0;
        margin-top: 16px;
        font-weight: 700;
        opacity: .6;

        cursor: pointer;

        &.active {
            color: var(--color-selected);
            cursor: default;
            opacity: 1;

            border-bottom: 2px solid var(--color-selected);
            border-radius: 1px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        overflow: auto;
        background: var(--color-app-bar);
        > span {
            margin-top: unset;
            padding-bottom: 16px;
            border-bottom: unset;

        }
        box-shadow: 0 1px 3px -2px rgba(60, 64, 67, 0.302), 0 4px 8px -2px rgba(60, 64, 67, 0.149);
    }
}
