.operators {
    .passwordAlert {

        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        background: var(--color-accent-darker);
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

    .tableOperators {
        table {
            width: 100%;

            td, th {
                &:last-child {
                    width: 3%;
                }
            }

            tr td {
                cursor: pointer;
            }
        }
    }
}
