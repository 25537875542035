.selectMultipleField {
    .label {
        color: var(--color-text-paragraph-inverse);
        font-weight: bold;
    }

    .options {
        margin: 8px 0;

        span {
            cursor: pointer;
            padding-right: 8px;
            font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

            &.active {
                color: var(--color-accent)
            }
        }
    }
}
