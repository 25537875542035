.iconButton {

    cursor: pointer;
    $padding: 8px;

    &.default {
        $size: 20px;

        padding: $padding;

        width: $size;
        height: $size;
        border-radius: 50%;
        text-align: center;

        background-color: transparent;
        transition: background-color 0.3s;

        img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover:not(.disabled) {
            background-color: var(--color-icon-button-hover);
        }
    }

    &.slim {
        padding: 0 $padding;
        line-height: 0;

        &:hover img {
            filter: invert(0.4);
        }
    }

    &.normal {
        img {
            width: 16px;
        }
    }

    &.big {
        img {
            width: 24px;
        }
    }

    &.disabled {
        img {
            filter: invert(0.4);
        }

        cursor: default;
    }
}
