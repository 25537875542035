.dashboard {
    margin: 16px;

    .menu {
        display: flex;
        flex-wrap: wrap;

        .item {
            margin: 16px 24px;
            padding: 16px;
            border-radius: var(--radius-default);

            background: var(--color-background-medium-dark);

            display: flex;
            align-items: center;

            cursor: pointer;

            &:hover {
                background: var(--color-background-level);
            }

            img {
                width: 24px;
            }

            span {
                margin-left: 16px;
                font-weight: bold;
                font-family: "Biotif", sans-serif;
            }

        }
    }

    .orders {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
