.baseFormOperator {
    width: 360px;

    h2 {
        margin-top: 24px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
    }

    .password {
        font-weight: bold;
        font-size: 1.2rem;
    }
}
