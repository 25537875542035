@import '../../../mixins';

.autocompleteField {
    @include fieldWithLabel;

    align-items: center;

    .autocompleteOptions {
        @include selectAnOptionDialog;

        top: 0;
        transform: translateY(100%);
    }

    &.open .autocompleteOptions {
        display: unset;
    }
}
