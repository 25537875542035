@import '../../../variables';

.breadCrumb {
    padding-top: 8px;
    padding-left: 24px;

    display: flex;
    align-items: center;
    font-size: 12px;

    > * {
        font-weight: bolder;
        cursor: default;

        &.hasLink {
            cursor: pointer;
            font-weight: 400;
        }

        &:not(:last-child):after {
            content: ' ';

            display: inline-block;
            $size: 4px;
            height: $size;
            width: $size;

            border-radius: 50%;
            margin: 2px 8px;
            background-color: var(--color-text-small-subtitle);
        }
    }

    @media screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        display: none;
    }
}
